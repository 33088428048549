export const COLORS = {
  generalBlue: '#2250FC',
  generalBlack: '#000000e6',
  primaryBlack: '#00000080',
  primaryBlack2: '#0000004d',
  gbA12: '#2250FC1f',
  gbA16: '#2250FC29',
  gbA20: '#2250FC33',
  border: '#DCE1EB',
  grayIcons: '#7A8499',
  lightBg: '#F7F9FB',
  white: '#FFFFFF',
};
